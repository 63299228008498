import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/promowanie-b2b-questy.jpg";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";
import * as styles from "./index.module.css";


export const query = graphql`
  {
    karta: file(relativePath: { eq: "karta-produktu-questy.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    promocje: file(relativePath: { eq: "promocja-ceny-kontrahenta.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
        baner: file(relativePath: { eq: "questy-baner1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
            promowane: file(relativePath: { eq: "promowane.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
                znaczniki: file(relativePath: { eq: "znaczniki-questy.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
                    ogloszenie: file(relativePath: { eq: "tresc-ogloszenia.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
                        newsletter: file(relativePath: { eq: "newsletter.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
const PromowanieB2B = ({ data }) => {
  return (
    <Artykul
      title="Promowanie produktów w&nbsp;platformie sprzedażowej&nbsp;B2B"
      articleImage={obrazekArtykulu}
      articleImageAlt="Promowanie produktów w platformie sprzedażowej B2B"
      metaTitle="Promowanie produktów w platformie sprzedażowej B2B"
      metaDescription="Poznaj posób na efektywne budowanie relacji z klientami ✅ Jak promować produkty w platformie sprzedażowej B2B? • Video 2021"
    >
      <p
        style={{
          paddingTop: 20,
        }}
      >Budowanie relacji z klientami odbywa się za pośrednictwem wielu sposobów i przyjmuje wiele form. 
      Jednym z&nbsp;poziomów dbania o relacje biznesowe jest zapewnienie efektywnej i&nbsp;profesjonalnej komunikacji z&nbsp;kontrahentem. 
      Dostarczanie merytorycznej wiedzy o produktach i&nbsp;budowanie wizerunku eksperta stanowią 
      kluczowe znaczenie również pod względem promowania produktów w platformie sprzedażowej. W&nbsp;tym&nbsp;artykule 
      poznasz skuteczne sposoby na wyróżnianie oferty produktowej, które<strong> przełożą się na relacje z&nbsp;klientami 
      B2B i&nbsp;pomogą Twojej firmie osiągać jeszcze lepsze wyniki</strong>.
      </p>
      
      <div className="text-block">
        <LiteYouTubeEmbed
          poster="maxresdefault"
          id="5vQdn_M_SBU"
          title="Promowanie produktów w platformie sprzedażowej B2B"
        />
      </div>
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Rola platformy sprzedażowej w obsłudze klienta B2B
      </h2>
      <p>
       Platforma sprzedażowa B2B to system informatyczny wspierający cały proces internetowej 
       sprzedaży hurtowej w firmie. Jej głównym celem jest generowanie zysku (dzięki, np. automatyzacji 
        procesów i wprowadzeniu samoobsługi kontrahentów), ale również i&nbsp;budowanie relacji z klientami. 
       Platforma sprzedażowa jest więc rozwiązaniem, które<strong> znajdzie zastosowanie przedsiębiorstwach 
       każdego rodzaju</strong>, niezależnie od ich branży oraz&nbsp;wielkości.
      </p>
      <p>
       Funkcjonalność tego typu rozwiązania zapewnia wiele różnych sposobów na wyróżnianie 
       produktów i&nbsp;promowanie konkretnych części oferty. To obszar aktywności przedsiębiorstwa, 
       którego nie sposób przecenić – pominięcie działań promocyjnych bardzo szybko może 
       zaowocować utratą klienta na rzecz konkurencji, która takie działania z pewnością 
       będzie prowadzić. Dbając o&nbsp;relacje z klientami za pośrednictwem tych rozwiązań niskim 
       kosztem można<strong> zwiększyć ich komfort zakupów online</strong>, co z kolei przełoży się na<strong> częstsze 
       składanie przez nich zamówień</strong>.
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz promować swoje produkty w platformie sprzedażowej B2B?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Skontaktuj się z nami już teraz i uzyskaj dostęp do darmowej 30-dniowej wersji próbnej systemu do sprzedaży&nbsp;B2B

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i wyróżnić swoją ofertę
            </Link>
          </div>
          </div>
    </section>
     <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
       Atrakcyjna kartoteka produktów
      </h3>
      <p>
        Nie ulega wątpliwości, że dobry produkt potrafi wypromować się sam – stąd też kluczowe w&nbsp;promowaniu 
        oferty na platformie sprzedażowej jest zbudowanie atrakcyjnej kartoteki produktów. Należy zadbać o 
        <strong> szczegółowe i&nbsp;rozbudowane opisy, zawarcie pełnej specyfikacji i dodanie wielu zdjęć produktów </strong>– wszystko 
        to wpływa na Twoją relację z klientem. Takie wyróżnienie produktów pozwoli Twoim kontrahentom na 
        uzyskanie wszystkich najważniejszych informacji już na starcie procesu sprzedażowego. Dostęp do 
        wszystkich tych niezbędnych do dokonania zakupu informacji w każdym miejscu i o każdej porze 
        przełoży się ostatecznie na lepsze wyniki sprzedażowe Twojej firmy.</p>
     
       <Lightbox
        style={{
          maxWidth: 600,
          margin: "0 auto",
        }}
        images={[data.karta]}
        alts={["Karta produktu w systemie B2B ze zdjęciami i opisem"]}
      />
      <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
      Polityka promocyjna i indywidualne cenniki
      </h3>
      <p>
        Następny ze sposobów promowania produktów w&nbsp;platformie sprzedażowej B2B polega na określaniu 
        indywidualnych cen kontrahentów zgodnie z&nbsp;wynegocjowanymi warunkami oraz&nbsp;prowadzeniu własnej 
        polityki promocyjnej. Integracja z&nbsp;programami handlowo-magazynowymi zapewni możliwość łatwego 
        importu rabatów i&nbsp;promocji przypisanych poszczególnym kontrahentom. </p>
      <p>
       Mogą one przyjąć formę zarówno wartościową (np. rabat w wysokości 200 zł na dany produkt), jak i&nbsp;procentową 
       (np. 15% zniżki). Takie rabaty i&nbsp;promocje można również dodać ręcznie po stronie platformy sprzedażowej, 
       jak i&nbsp;zaimportować z pliku – co jest szczególnie przydatne w przypadku braku integracji z&nbsp;systemem ERP. 
       Tego rodzaju funkcjonalność jest nie tylko świetnym sposobem na<strong> budowanie relacji z&nbsp;klientami </strong>dzięki 
       oferowaniu indywidualnych zniżek, ale też pomoże<strong> skupić ich uwagę na wybranych produktach </strong>
       oraz – pośrednio – pozytywnie<strong> wpłynąć na konwersję sprzedaży </strong>w firmie.
      </p>
      <Lightbox
        style={{
          maxWidth: 600,
          margin: "0 auto",
        }}
        images={[data.promocje]}
        alts={["Promocje i indywidualne ceny kontrahentów w systemie B2B"]}
      />

       <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
       Banery reklamowe i wyróżnianie oferty
      </h3>
      <p>
        Trzeci sposób promowania oferty produktowej i&nbsp;poszczególnych towarów w platformie sprzedażowej B2B 
        polega na wykorzystaniu banerów reklamowych. We wszystkich naszych rozwiązaniach kontrahent zobaczy 
        je<strong> tuż po zalogowaniu</strong>, na stronie głównej, a ich<strong> pełna responsywność </strong>sprawi, że 
        zawsze dostosują się 
        one do urządzenia, z którego będzie korzystał klient (np. komputer, telefon). Ten sposób ekspozycji 
        banerów zapewni, że Twoi kontrahenci już po wejściu do platformy sprzedażowej będą mogli zapoznać się 
        ze<strong> wszystkimi produktami z&nbsp;oferty, które w danym momencie będą miały dla Ciebie największe znaczenie</strong>. 
        Po kliknięciu w&nbsp;baner kontrahent może zostać przeniesiony na dowolną kategorię produktów lub ich 
        grupę z&nbsp;określonym tagiem (np. “promocje”), wybrany landing page w platformie sprzedażowej lub na 
        jakąkolwiek stronę zewnętrzną.</p>
     
       <Lightbox
        style={{
          maxWidth: 600,
          margin: "0 auto",
        }}
        images={[data.baner]}
        alts={["Baner reklamowy w systemie B2B - wyeksponuj swoje produkty"]}
      />

             <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
       Panel promowanych produktów
      </h3>
      <p>
       Panel z promowanymi produktami to kolejny z&nbsp;niemożliwych do przecenienia sposobów na budowanie 
       relacji z&nbsp;klientami B2B i wyróżnienie oferty produktowej. Kontrahenci mają do niego dostęp z dwóch 
       różnych miejsc systemu: na stronie głównej, jak i w samej kartotece produktów, tuż nad standardową 
       ofertą. Wszystkie promowane w ten sposób produkty mogą być wybrane przez Ciebie oraz ulegać 
       automatycznej zmianie w&nbsp;określonych odstępach czasu na inne elementy oferty. To kolejna istotna 
       funkcjonalność systemu, która pozwoli Ci na<strong> skupienie uwagi klientów na wybranych obszarach</strong>, 
       przykładowo na wszystkich produktach, które chcesz wycofać z&nbsp;oferty, a więc zależy Ci na ich szybkiej sprzedaży.
</p>
     
       <Lightbox
        style={{
          maxWidth: 600,
          margin: "0 auto",
        }}
        images={[data.promowane]}
        alts={["Galeria produktów promowanych w platformie sprzedażowej B2B"]}
      />

                   <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
       Oznaczenia produktów
      </h3>
      <p>
     Kolejną z kluczowych funkcji platformy sprzedażowej wspierających relacje z klientami B2B są znaczniki 
     produktów, nazywane również tagami. Umożliwiają one Twoim kontrahentom<strong> sortowanie oferty produktowej </strong>
     zgodnie z&nbsp;oznaczeniami, które przypisane zostały poszczególnym elementom kartoteki. Zarówno nazwa 
     znaczników jak i wszystkie produkty, które będą nimi objęte, zależne są wyłącznie od Ciebie.
</p>
<p><ul>
<li>Oznaczenie tagiem “<strong>PROMOCJA</strong>” pozwala na sortowanie oferty według tych produktów, które w danym momencie objęte są 
rabatami i promocjami. Tego typu filtrowanie dostępne jest kontrahentom w dwóch miejscach – zarówno na liście wszystkich 
znaczników, jak i w przypadku bezpośredniego wpisania zapytania w&nbsp;wyszukiwarkę platformy sprzedażowej.</li>
<li>Oznaczenie tagiem “<strong>NOWOŚĆ</strong>” daje Twoim klientom podgląd wszystkich nowych elementów Twojej oferty sprzedażowej. 
Oznaczenie w ten sposób nowych produktów w Twoim sklepie, jak i tych, które dopiero wchodzą na rynek pozwoli zapewnić 
im widoczność i możliwie najwyższą sprzedaż już od pierwszych sekund dodania ich do oferty. </li>
</ul></p>
     
       <Lightbox
        style={{
          maxWidth: 600,
          margin: "0 auto",
        }}
        images={[data.znaczniki]}
        alts={["Znaczniki systemu B2B - nowości, promocje - sortuj produkty"]}
      />

                   <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
       Aktualności i ogłoszenia
      </h3>
      <p>
       Zadbanie o efektywną i profesjonalną komunikację z kontrahentami to kolejny ważny element budowania 
       relacji z klientem B2B. Dostarczanie im merytorycznej wiedzy w obszarze prowadzonej działalności 
       pozwoli na kreowanie wizerunku eksperta, usprawniajac jednocześnie komunikację sprzedażową i marketingową.
</p>
<p>Narzędziem, które świetnie sprawdzi się w tym kontekście jest elastyczny panel z ogłoszeniami i&nbsp;aktualnościami, 
którego głównym zadaniem jest przekazywanie klientom (lub ich określonym grupom)<strong> wszystkich najważniejszych 
informacji o promocjach i ofertach specjalnych</strong>. Do tego rodzaju ogłoszeń możesz dodać zarówno tekst, jak i jakiekolwiek 
inne media – np. linki, obrazy i&nbsp;fotografie czy załączniki, a&nbsp;wspomniane wcześniej banery reklamowe mogą zawierać 
odnośniki, które przekierują Twoich klientów do&nbsp;wybranych komunikatów.</p>
     
       <Lightbox
        style={{
          maxWidth: 600,
          margin: "0 auto",
        }}
        images={[data.ogloszenie]}
        alts={["Ogłoszenia i aktualności w systemie B2B - zadbaj o profesjonalną komunikację z klientami"]}
      />

                         <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
      Personalizacja newslettera
      </h3>
      <p>
       Ostatnim z kluczowych elementów komunikacji w&nbsp;budowaniu relacji z klientem, na który chcemy zwrócić 
       Twoją uwagę, polega na sprawny wykorzystaniu bazy mailingowej. To świetny sposób na przypomnienie o 
       sobie kontrahentom dzięki alternatywnemu kanałowi komunikacji i na odnowienie relacji biznesowych. 
       Platforma sprzedażowa umożliwi Ci<strong> spersonalizowanie newslettera </strong>w sposób, który zachęci kontrahentów 
       do zalogowania do Twojego sklepu i&nbsp;zapoznanie z najnowszą kartoteką produktów.
</p>
<p>Indywidualne wiadomości do poszczególnych klientów pozwolą na<strong> dostosowanie komunikatu zgodnie z&nbsp;Waszą 
dotychczasową relacją </strong>i&nbsp;wyszczególnienie tych elementów oferty produktowej, które dla danego klienta mogą 
mieć największe znaczenie. Tego rodzaju wiadomości wysyłane są bezpośrednio z poziomu platformy sprzedażowej, 
dzięki czemu nie będziesz musiał wykorzystywać jakichkolwiek zewnętrznych systemów lub programów.</p>
            <Lightbox
        style={{
          maxWidth: 600,
          margin: "0 auto",
        }}
        images={[data.newsletter]}
        alts={["Newsletter w platformie sprzedażowej B2B - profesjonalny kontakt z kontrahentami"]}
      />
<h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Słowem podsumowania…
      </h2>
      <p>
     Bez wątpienia wyróżnianie i promowanie oferty produktowej jest procesem, który może odbywać się na 
     wiele różnych form i sposobów, a&nbsp;jednocześnie ma nieoceniony wpływ na budowanie Twojej relacji z 
     klientem B2B. Wybrane przez Ciebie rozwiązania powinny odzwierciedlać potrzeby Twoich kontrahentów i 
     branży, w której działasz, jednak najważniejsza zasada polega na tym, by jakiekolwiek działania 
     promocyjne prowadzić. Ich wykorzystanie pomoże Ci przede wszystkim w zwiększeniu sprzedaży, aktywnej 
     walce z konkurencją oraz&nbsp;pozytywnie wpłynie na lojalność Twoich kontrahentów. 
      </p>

<section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz promować swoje produkty w platformie sprzedażowej B2B?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Skontaktuj się z nami już teraz i uzyskaj dostęp do darmowej 30-dniowej wersji próbnej systemu do sprzedaży&nbsp;B2B

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i wyróżnić swoją ofertę
            </Link>
          </div>
          </div>
    </section>

      <p>
        Sprawdź też inne nasze artykuły na podobne tematy:
        <ul>
          <li>
            <Link to="/czym-jest-platforma-b2b/"> Czym jest platforma B2B?</Link>
          </li>
          <li>
            <Link to="/system-b2b-funkcje/">
              {" "}
              System B2B - przeznaczenie i najważniejsze funkcje{" "}
            </Link>
          </li>
          <li>
            <Link to="/sklep-b2b-jak-zaczac/">
              {" "}
              Sklep B2B - jak przygotować ofertę dla klienta hurtowego?{" "}
            </Link>
          </li>
        </ul>
      </p>
    </Artykul>
  );
};

export default PromowanieB2B;
